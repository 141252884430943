import { useEffect } from "react";

const OsteoboostDomain = "app.osteoboost.com";
const WellenDomain = "app.getwellen.com";

const OsteoboostTitle = "Osteoboost - Comprehensive bone health solution";
const WellenTitle = "Wellen - Personalized exercise program for osteoporosis";

const useWindowTitle = () => {
  useEffect(() => {
    const hostname = window.location.hostname;

    const domainTitleMap: { [key: string]: string } = {
      [OsteoboostDomain]: OsteoboostTitle,
      [WellenDomain]: WellenTitle
    };

    // Set the title based on the hostname, default if not found
    document.title = domainTitleMap[hostname] || WellenTitle;
  }, []);
};

export default useWindowTitle;
